// 例如在 src/views/Home.vue 中
<template>

    <div class="container1">

      <div ref="myLeftElement" :class="[ leftisActive ?left: 'leftactive']">
        <LeftSidebar @updateTitleName="updateTitleName"></LeftSidebar>
      </div>

      <div  class="center">
        <header>
          <nav class="navbar">
            <button @click="lefttoggleWidth" class="expand-button" id="left-button">&#9654;</button>
            <div class="navbar-content">
              <h1>{{ titleNmae }}</h1>
            </div>
            <div><font-awesome-icon icon="phone" @click="switchVideoCall" /> <!-- 使用 Font Awesome 图标 --></div>
            <button @click="righttoggleWidth" class="expand-button" id="right-button">☰ </button>
          </nav>
        </header>
        <VideoCall v-if="titleNmae!=''  && isVideoCall" :to="titleNmae" ></VideoCall>
<!--        v-if="titleNmae!=''"-->
        <CenterMain v-if="titleNmae!=''  && !isVideoCall" :to="titleNmae" @lefttoggleWidth="lefttoggleWidth" @righttoggleWidth="righttoggleWidth" ></CenterMain>
      </div>

      <div ref="myRightElement" :class="[rightisActive? right: 'rightactive' ]">
        <RightSidebar></RightSidebar>
      </div>
    </div>
<!--    <footer>-->
<!--      Footer-->
<!--    </footer>-->



</template>

<script>
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import CenterMain from './Center';
import VideoCall from './VideoCall';
// import axios from '../plugins/axios';
// 注入Axios实例
// import {inject} from "vue";
// const axios = inject('axios');

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    LeftSidebar,
    CenterMain,
    RightSidebar,
    VideoCall,
  },
  data() {
    return{
      isVideoCall: false,
      titleNmae:'',
      right:'right',
      left:'left',
      leftisActive: true, // 控制左侧导航栏是否收起的响应式数据
      rightisActive: true, // 控制右侧��天窗口是否收起的响应式数据
    }
  },
  refs: {
    myRightElement: null, // 引用将被绑定到具有 class="my-class" 的元素上
    myLeftElement: null, // 引用将被绑定到具有 class="my-class" 的元素上
  },
  methods: {
    switchVideoCall(){
      this.isVideoCall =!this.isVideoCall;
    },
    updateTitleName(titleN){
      //console.log("titleN--"+titleN);
      this.titleNmae = titleN
    },
    lefttoggleWidth(){
      this.leftisActive = !this.leftisActive; // 当点击注册按钮时，激活 'active' 类
      this.left='leftactive1';
      const element = this.$refs.myLeftElement;
      if (element) {
        const computedStyle = window.getComputedStyle(element);
        const elementWidth = computedStyle.width; // 获取宽度并存储在 data 属性中
        // 使用正则表达式提取数值部分
        const widthValue = parseFloat(elementWidth.match(/\d+(\.\d+)?/)[0]);
        console.log("elementWidth--"+elementWidth);
        if (widthValue==0){
          this.leftisActive =true;
        }
      } else {
        console.warn('Element not found!');
      }
    },
    righttoggleWidth(){
      this.rightisActive = !this.rightisActive; // 当点击注册按钮时，激活 'active' 类
      this.right='rightactive1';
      const element = this.$refs.myRightElement;
      if (element) {
        const computedStyle = window.getComputedStyle(element);
        const elementWidth = computedStyle.width; // 获取宽度并存储在 data 属性中
        // 使用正则表达式提取数值部分
        const widthValue = parseFloat(elementWidth.match(/\d+(\.\d+)?/)[0]);
        console.log("elementWidth--"+elementWidth);
        if (widthValue==0){
          this.rightisActive =true;
        }
      } else {
        console.warn('Element not found!');
      }
    },
    async fetchData() {
      try {
        const response = await this.$axios.get('/api/data');
        // console.log(response.data);
        console.log(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  },
};
</script>
<style>
*{
  padding: 0;
  margin: 0;
}
/*body{*/
/*  height: 100vh;*/
/*  width: 100%;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/
body {
  min-width: 550px;
}

header {
  background-color: #333;
  color: white;
  padding: 10px 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.expand-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 10px;
}

.expand-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
#left-button{
  transform: rotate(-180deg);

}

.navbar-content {
  flex-grow: 1;
  text-align: center;
}

.navbar-content h1 {
  margin: 0;
}


.container1 {
  display: flex;
  /*height: 100vh; !* 可选：使容器高度占满视口高度 *!*/
}
.left {
  width: 200px; /* 固定宽度 */
  /*background-color: lightcoral;*/
}
.center {
  flex: 1; /* 自适应宽度 */
  /*background-color: lightblue;*/
}
.right {
  width: 230px; /* 固定宽度 */
  /*background-color: lightgreen;*/
}
.leftactive{
  width: 0px; /* 固定宽度 */

}
.rightactive{
  width: 0px; /* 固定宽度 */

}
.leftactive1{
  width: 200px; /* 固定宽度 */

}
.rightactive1{
  width: 200px; /* 固定宽度 */

}
/* 媒体查询：当屏幕宽度小于600px时（可调整阈值以适应不同手机） */
@media (max-width: 600px) {
  .left, .right {
    width: 0; /* 在手机上将宽度设置为0 */
    overflow: hidden; /* 防止内容溢出 */
  }
  .center {
    /*flex: none; !* 取消自适应，但在这里其实不需要，因为已经没有其他列占用空间了 *!*/
    /*height: 100%;*/
    width: 100%; /* 可选：明确设置中间列为100%宽度 */
  }
  .remote-video {
    right: 10px; /* 在手机上将宽度设置为0 */
    overflow: hidden; /* 防止内容溢出 */
  }
}











</style>
