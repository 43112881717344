<template>
  <div>
    <input v-model="plaintext" placeholder="Enter plaintext" />
    <button @click="encryptMessage">Encrypt</button>
    <p>Ciphertext: {{ ciphertext }}</p>
    <button @click="decryptMessage">Decrypt</button>
    <p>Decrypted text: {{ decryptedtext }}</p>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js';

export default {
  name: "AES_CBC",
  data() {
    return {
      plaintext: 'This is a secret message.',
      ciphertext: '+P1dNegT66zrxlLVVUarp+Ge1ZVKsmkORsNbuAjUv90=',
      decryptedtext: '',
      key: CryptoJS.enc.Hex.parse('6c86945fcb5f6cf09f600852406b4c4e'), // 32字节的密钥，对应256位AES
      iv: CryptoJS.enc.Hex.parse('6c86945fcb5f6cf09f600852406b4c4e') // 16字节的IV，对应AES的块大小
    };
  },
  methods: {
    encryptMessage() {

      //this.key = this.atob_to_string('6c86945fcb5f6cf09f600852406b4c4e');
      //this.iv = this.atob_to_string('1a1112131415151718191a');
      //this.plaintext = this.encodeToUtf8('This is a secret message.');
      const ciphertext = CryptoJS.AES.encrypt(this.plaintext, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      this.ciphertext = ciphertext.toString();
    },
    decryptMessage() {
      const bytes = CryptoJS.AES.decrypt(this.ciphertext, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      this.decryptedtext = bytes.toString(CryptoJS.enc.Utf8);
    },
    atob_to_string(dateStr){
      // 使用 atob 解码 Base64 字符串为 UTF-16 字符串
      //const utf16String = atob('6c86945fcb5f6cf09f600852406b4c4e');
      const utf16String = atob(dateStr);
      // 创建一个 TextEncoder 实例，用于将字符串转换为 UTF-8 字节数组
      const encoder = new TextEncoder();
      const utf8Uint8Array = encoder.encode(utf16String);
      return Array.from(utf8Uint8Array);
    },
    encodeToUtf8(plaintextInput) {
      // 创建一个 TextEncoder 实例，用于将字符串转换为 UTF-8 字节数组
      const encoder = new TextEncoder('utf-8');

      // 将字符串转换为 UTF-8 字节数组
      const utf8Uint8Array = encoder.encode(plaintextInput);

      // 如果需要将 Uint8Array 转换为普通的数组（可选）
      return Array.from(utf8Uint8Array);
    },
  }
};
</script>

<style scoped>
/* 添加你的样式 */
</style>

