// src/plugins/axios.js
import axios from 'axios';
import {useAuthStore} from "@/stores";


const instance = axios.create({
    // baseURL: 'https://api.example.com', // 替换为你的 API 基础 URL
    // baseURL: import.meta.env.VITE_API_BASE_URL, // 对于Vite项目
    baseURL: process.env.VUE_APP_API_BASE_URL, // 对于Vue CLI项目
    timeout: 100000,
    headers: {
        'X-Custom-Header': 'foobar',
        // 其他头部可以添加
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Expose-Headers': 'Authorization',
        'Access-Control-Max-Age': '86400',

    },
});
// 添加请求拦截器
instance.interceptors.request.use(
    config => {
        const store = useAuthStore();
        // 检查请求的 URL 是否为登录或注册
        const isLoginOrRegister = config.url.startsWith('/authenticate') ||
            config.url.startsWith('/api/users/register') ||
            config.url.startsWith('/api/users/login');
        // 在这里可以添加 token 或其他请求头
        // const token = localStorage.getItem('token');
        const token = store.getToken();  //

        //console.log("请求头新增token"+token);
        if (!isLoginOrRegister&&token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
// 添加响应拦截器
// instance.interceptors.response.use(
//     response => {
//         return response;
//     },
//     error => {
//         // 对响应错误做点什么，比如显示错误消息
//         console.error('Error:', error);
//         return Promise.reject(error);
//     }
// );


export default instance;
