<template>
  <div class="container">
    <h2>用户信息</h2>
    <div class="form-group">
      <font-awesome-icon icon="camera" /> <!-- 使用 Font Awesome 图标 -->
      {{name}}
    </div>

    <div class="password-reset-container">
      <button @click="showResetInfo = true">修改密码</button>
      <transition name="fade">
        <div v-if="showResetInfo" class="reset-info-box">
          <p>请输入原密码与新密码。</p>
          <div v-for="(password, index) in passwords" :key="index">
            <div class="password-toggle">
              <input  :type="password.isPasswordVisible ? 'text' : 'password'" v-model="password.value"
                     :placeholder="password.placeholder">
              <button type="button" id="togglePwd" @click="togglePasswordVisibility(index)">
                <!--        <span id="eyeIcon" class="fa-fa-eye"></span>-->
                <font-awesome-icon class="fa-fa-eye" :icon="['fas', !password.isPasswordVisible ? 'eye-slash' : 'eye']" />
              </button>
            </div>
          </div>
<!--          <div class="password-toggle">-->
<!--            <input ref="myInput" type="password" v-model="newPwd" id="pwd1" placeholder="新密码">-->
<!--            <button type="button" id="togglePwd1" @click="togglePasswordVisibility()">-->
<!--              &lt;!&ndash;        <span id="eyeIcon" class="fa-fa-eye"></span>&ndash;&gt;-->
<!--              <font-awesome-icon class="fa-fa-eye" :icon="['fas', isPasswordVisible1 ? 'eye-slash' : 'eye']" />-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="password-toggle">-->
<!--            <input ref="myInput" type="password" v-model="renewPwd" id="pwd2" placeholder="确认密码">-->
<!--            <button type="button" id="togglePwd2" @click="togglePasswordVisibility()">-->
<!--              &lt;!&ndash;        <span id="eyeIcon" class="fa-fa-eye"></span>&ndash;&gt;-->
<!--              <font-awesome-icon class="fa-fa-eye" :icon="['fas', isPasswordVisible2 ? 'eye-slash' : 'eye']" />-->
<!--            </button>-->
<!--          </div>-->
          <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
          <div>
            <button @click="resetPassword">发送</button>
            <button @click="showResetInfo = false">关闭</button>
          </div>

        </div>
      </transition>
    </div>
    <Modal v-model:isVisible="showModal">
      <template #header>
        <h3></h3>
      </template>
      <p>{{showModalMessage}}</p>
      <template #footer>
<!--        <button @click="confirmAction">Confirm</button>-->
        <button @click="showModal = false">关闭</button>
      </template>
    </Modal>

  </div>
</template>

<script>
import {useAuthStore} from "@/stores";
import CryptoJS from "crypto-js";
import Modal from './Modal.vue';


export default {
  name: "RightSidebar",
  components: {
    Modal
  },
  data() {
    return{
      name:'',
      showResetInfo: false, // 控制密码信息栏是否收起的响应式数据
      passwords: [
        { value: '', isPasswordVisible: false ,placeholder:'原密码'},
        { value: '', isPasswordVisible: false ,placeholder:'新密码'},
        { value: '', isPasswordVisible: false ,placeholder:'确认密码'}
      ],
      md5_secret_key: process.env.MD5_SECRET_KEY,
      errorMessage: '', // 用于存储错误信息的变量
      showModal: false,
      showModalMessage: '',
    }
  },
  created() {
    // 在组件创建后，从 store 中获取用户信息
    // 注意：这里我们不会直接将 store 的状态赋值给 data 的 user，
    // 而是使用计算属性或直接在模板中通过 mapState 来访问。
    // 但为了演示，我们暂时在这里直接赋值（这不是最佳实践）。

    const userStore = useAuthStore();
    //console.log(userStore.$state);
    //console.log(userStore.userInfo.name);
    this.name =userStore.userInfo.name;
    //this.$store.state.user ? (this.name = this.$store.state.user.userInfo.name) : console.warn('User not found in store!');

    // 更好的做法是使用计算属性或 mapState 辅助函数。
  },
  watch: {
    // 监听 passwords 数组的变化，当最后一个和倒数第二个密码变化时进行检查
    passwords: {
      handler() {
        this.checkPasswords();
      },
      deep: true, // 需要深度监听，因为 passwords 是一个数组，包含对象
      immediate: true // 立即执行一次监听器
    }
  },
  methods: {
    togglePasswordVisibility(index) {
      // 根据传入的索引切换对应密码输入框的可见性
      this.passwords[index].isPasswordVisible = !this.passwords[index].isPasswordVisible;
      // this.isPasswordVisible = !this.isPasswordVisible;
      // this.$nextTick(() => {
      //   //const passwordInput = this.$el.querySelector('input[type="password"]');
      //   const passwordInput = this.$refs.myInput;
      //   passwordInput.type = this.isPasswordVisible ? 'text' : 'password';
      // });
    },

    checkPasswords() {
      // 检查最后两个密码是否相同
      if (this.passwords.length >= 2) {
        const secondLast = this.passwords[this.passwords.length - 2].value;
        const last = this.passwords[this.passwords.length - 1].value;
        if (secondLast !== last) {
          this.errorMessage = '两次密码不相同！';
        } else {
          this.errorMessage = '';
        }
      }
    },

    async resetPassword(){
      const store = useAuthStore();
      // 在这里，我们直接从本地变量中获取用户名和密码
      // 并调用 store 的 resetPwd 方法进行密码修改
      const combinedString0=this.passwords[0].value+this.md5_secret_key;
      var md5Hash0 = CryptoJS.MD5(combinedString0).toString(CryptoJS.enc.Hex);
      const combinedString=this.passwords[2].value+this.md5_secret_key;
      // 使用 CryptoJS 计算 MD5 哈希值
      var md5Hash = CryptoJS.MD5(combinedString).toString(CryptoJS.enc.Hex);

      try {
        await store.resetPwd({"name": this.name, "hsmac": md5Hash,"spare1":md5Hash0});
        this.showResetInfo = false;

        this.showModalMessage = "密码修改成功";

        // 登录后，store 的状态会自动更新，因为 errorMessage 是一个计算属性
        // 所以它会自动反映 store 中 errorMessage 的变化
        // 如果登录方法没有抛出错误，说明登录成功
        // 进行页面跳转
        // await router.push({name: 'Home'});
        //await this.$router.push({name: 'Home'});
      } catch (error) {
        // 如果登录方法抛出了错误，说明登录失败
        // 处理登录错误，例如显示错误消息
        this.showModalMessage = "密码修改失败";
        console.error('修改密码 failed:', error);
        // 可以在这里添加额外的错误处理逻辑，比如显示一个错误弹窗
      }
      this.showModal = true;
    },

  },
}
</script>
.password-reset-container {
text-align: center;
margin-top: 20px;
}

.reset-info-box {
background-color: #fff;
border: 1px solid #ccc;
padding: 20px;
width: 300px;
margin: 0 auto;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
border-radius: 5px;
}

.fade-enter-active, .fade-leave-active {
transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to  {
opacity: 0;
}

.password-toggle {
position: relative;
display: flex;
align-items: center;
}

.password-toggle input[type="password"] {
flex: 1;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
margin-right: 10px;
}

.password-toggle button {
background: none;
border: none;
cursor: pointer;
padding: 5px;
font-size: 18px;
color: #007bff;
}

.password-toggle button:hover {
color: #0056b3;
}
.fa-eye-slash:before {
content: "\f070"; /* Font Awesome eye-slash icon */
}
<style scoped>

</style>
