<template>
  <div class="video-call-interface">
    <section class="video-windows">
      <video ref="remoteVideo" class="local-video" autoplay muted></video>
      <video ref="localVideo"  class="remote-video" autoplay></video>
    </section>
    <aside class="controls">
      <div>
        <button v-if="!isSendVideo"  :style="{ backgroundColor: buttonColor }" @click="sendVideo">{{ isHangUp ? '' : '发起视频' }}</button>
        <button v-if="isSendVideo"   :style="{ backgroundColor: buttonColor }" @click="hangUp">{{ isHangUp ? '挂断' : '接听' }}</button>
        <button @click="toggleMicrophone">{{ isMicrophoneMuted ? '取消静音' : '静音' }}</button>
        <button @click="toggleCamera">{{ isCameraDisabled ? '开启摄像头' : '关闭摄像头' }}</button>
      </div>
    </aside>
    <!-- 如果有聊天窗口，可以在这里添加 -->
  </div>
</template>

<script>
import {useAuthStore} from "@/stores";

export default {
  name: "VideoCall",
  data() {
    return {
      isSendVideo:false,
      buttonColor: "#0ceb3c",
      isHangUp:false,
      isMicrophoneMuted: false,
      isCameraDisabled: false,
      // 这里可以添加其他状态，如WebRTC连接状态等
      socket: null,
      wsBaseURL: process.env.VUE_APP_API_WS_BASE_URL, // 对于Vue CLI项目
      client:null,

    };
  },
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  watch:{
    to:{
      handler(newVal, oldVal) {
        console.log(`RTCProp myProp changed from ${oldVal} to ${newVal}`);
        //this.localto=this.to; // 初始化本地数据为 props 的值
        // 在这里执行你想要的逻辑
        //handler() {
        if (this.socket) {
          this.socket.close();
        }
        this.connectWebSocket();

      },
      immediate: true // 立即执行一次监听器
    },
  },
  methods: {
    sendVideo() {
        const store = useAuthStore();
        this.isSendVideo = !this.isSendVideo;
        var socket=this.socket;
        var client = this.client;
        var localVideo = this.$refs.localVideo;


        var remoteVideo = this.$refs.remoteVideo;
        // var localVideo = this.$refs.localVideo;
        this.client.ontrack=function(event){
          // remoteVideo.srcObject=event.streams[0];
          // remoteVideo.play();
          remoteVideo.srcObject=event.streams[0];
          remoteVideo.play();
        };

        navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        }).then((stream) => {
          // bend.srcObject=stream;
          localVideo.srcObject=stream;
          stream.getTracks().forEach(function (track) {
            client.addTrack(track,stream);
            //w3review.innerHTML = w3review.innerHTML + 'onopen ->client.addTrack 完成\r\n';
          })


        });

        this.client.createOffer().then((desc)=>{
        client.setLocalDescription(desc)
        //send server
        //var userInfo={"desc":desc,"uid":"1","state":"2"};
        var userInfo=  { type: 'webrtc', from: store.userInfo.name ,to: this.to,offer: desc,signature: 'offer'};
        socket.send(JSON.stringify(userInfo))
      })
    },
    hangUp() {
      if(this.isHangUp){
        this.isSendVideo = !this.isSendVideo;
        // 实现挂断逻辑，如关闭WebRTC连接等
        console.log('挂断通话');
      }
      //实现接听/挂断
      this.isHangUp = !this.isHangUp;
      this.buttonColor = !this.isHangUp?'#0ceb3c':'#eb0c29';

    },
    toggleMicrophone() {
      // 实现麦克风静音/取消静音逻辑
      this.isMicrophoneMuted = !this.isMicrophoneMuted;
      const localVideo = this.$refs.localVideo;
      localVideo.muted = this.isMicrophoneMuted;
      // 这里还需要处理远程用户的麦克风静音状态，可能需要通过WebRTC信令来同步
    },
    toggleCamera() {
      // 实现摄像头开启/关闭逻辑
      this.isCameraDisabled = !this.isCameraDisabled;
      const localVideo = this.$refs.localVideo;
      console.log(localVideo.muted);
      // 这里需要处理摄像头的实际开启/关闭，可能需要使用MediaDevices.getUserMedia()等方法
      // 注意：由于隐私和安全原因，浏览器可能不会允许在运行时动态开启/关闭摄像头
      // 因此，通常的做法是在组件挂载时请求摄像头权限，并在需要时显示/隐藏视频流
    },

    connectWebSocket() {
      let serverConfig = {
        "iceServers": [
          //{ "urls": "stun:xiexeini.lol:3478" },
          { "urls": ["turn:xiexeini.lol:3478"],
            "username": "xie",
            "credential": "x123456"
          }
        ]
      };

      this.client = new RTCPeerConnection(serverConfig);
      const store = useAuthStore();
      const params = "name="+store.userInfo.name+"&token="+store.userToken;
      this.socket = new WebSocket(this.wsBaseURL+'sendMeg?'+params);
      var client = this.client;
      var socket=this.socket;
      // var remoteVideo = this.$refs.remoteVideo;
      // var localVideo = this.$refs.localVideo;
      // this.client.ontrack=function(event){
      //   // remoteVideo.srcObject=event.streams[0];
      //   // remoteVideo.play();
      //   remoteVideo.srcObject=event.streams[0];
      //   remoteVideo.play();
      // };

      var to=this.to;
      this.client.onicecandidate=function (event) {
        var iceCandidate = event.candidate;
        var userInfo=  { type: 'webrtc', from: store.userInfo.name ,to: to,condidate: iceCandidate,signature: 'condidate'};
        //var userInfo={"uid":"1","state":"1","condidate":iceCandidate};
        //console.info('uid 1 发送 iceCandidate'+ JSON.stringify(userInfo));
        if(iceCandidate!=null)
        {
          //console.info("  用户1 发送的condidate"+iceCandidate)
          socket.send(JSON.stringify(userInfo));
          //client.addIceCandidate(new RTCIceCandidate(iceCandidate));
          //w3review.innerHTML = w3review.innerHTML + JSON.stringify(userInfo)+' 1发送ICE \r\n';
        }
      };


      this.socket.onopen = () => {
        console.log('RTCWebSocket connected');
        // navigator.mediaDevices.getUserMedia({
        //   audio: false,
        //   video: true,
        // }).then((stream) => {
        //   // bend.srcObject=stream;
        //   localVideo.srcObject=stream;
        //   stream.getTracks().forEach(function (track) {
        //     client.addTrack(track,stream);
        //     //w3review.innerHTML = w3review.innerHTML + 'onopen ->client.addTrack 完成\r\n';
        //   })
        //
        //
        // });
        // 发送连接请求报文（这里可以根据需要发送更详细的连接信息）
        socket.send(JSON.stringify({ type: 'webrtc', from: store.userInfo.name ,to: to, signature: 'ready'}));
      };

      this.socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        //console.log('Message'+message);
        //console.log(message);
        if (message.type === 'webrtc' && message.signature === 'ready') {
          console.log('RTCWebSocket connected ready');

        }else if(message.type === 'webrtc' && message.signature === 'answer'){
          //message.content = store.decryptMessage(message.content,this.privateKeyStr)
          client.setRemoteDescription(message.answer)
        }else if(message.type === 'webrtc' && message.signature === 'offer'){
          //message.content = store.decryptMessage(message.content,this.privateKeyStr)
          client.setRemoteDescription(message.offer);
          client.createAnswer().then(desc=>{
            client.setLocalDescription(desc);
            //var userInfo={"desc":desc,"uid":"2","state":"3"};
            var userInfo=  { type: 'webrtc', from: store.userInfo.name ,to: to,answer: desc,signature: 'answer'};
            socket.send(JSON.stringify(userInfo));
          });
          this.isSendVideo = true;
        }else if(message.type === 'webrtc' && message.signature === 'condidate'){
          //message.content = store.decryptMessage(message.content,this.privateKeyStr)
          if(message.content!=null)
          {
            client.addIceCandidate(new RTCIceCandidate(message.condidate))
          }
        }

      };

      this.socket.onclose = () => {
        console.log('RTCWebSocket disconnected');
      };

      this.socket.onerror = (error) => {
        console.error('RTCWebSocket error:', error);
      };
    },
    // 其他方法，如建立WebRTC连接、处理信令等


  },
  // 可以在这里添加生命周期钩子，如mounted()来初始化WebRTC连接等
};
</script>

<style scoped>
.video-call-interface {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /*width: 100vw;*/
  background-color: #f0f0f0;
}
.video-windows {
  /*position: absolute;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.local-video {
  /*position: absolute;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保视频填充整个容器，同时保持其宽高比 */
}

.remote-video {
  position: absolute;
  top: 50px; /* 根据需要调整 */
  right: 10px; /* 根据需要调整 */
  width: 200px; /* 根据需要调整 */
  height: 150px; /* 根据需要调整 */
  object-fit: cover; /* 同样确保视频填充其容器，同时保持宽高比 */
  z-index: 1; /* 确保远程视频在本地视频之上（如果需要的话） */
}
/* 媒体查询：当屏幕宽度小于600px时（可调整阈值以适应不同手机） */
@media (max-width: 600px) {
  /*.remote-video {*/
  /*  left: 10px; !* 在手机上将宽度设置为0 *!*/
  /*  overflow: hidden; !* 防止内容溢出 *!*/
  /*}*/
}
/* 其他样式 */
/*.video-windows {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  width: 80%;*/
/*  margin: 20px 0;*/
/*}*/

video {
  width: 45%;
  height: auto;
  background-color: #000;
}

.controls {
  display: flex;
  position: absolute;
  bottom: 0px;
  /*flex-direction: column;*/
  justify-content: center; /* 水平居中 */
  align-items: center;
  width: 80%;
  gap: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
