<template>
  <section class="weixin-friend-section">
    <h2>新的朋友</h2>
    <ul>
      <div v-for="(friend, index) in friendList" :key="index">
        <li class="weixin-friend-item" @click="titleNmae(friend.name)">
<!--          <img src="avatar1.jpg" alt="好友头像" class="weixin-avatar">-->
          <div class="weixin-avatar"><SvgCreate :name="friend.name" :bColor="'#1575d5'"></SvgCreate></div>
          <div class="weixin-friend-info">
            <span class="weixin-nickname">{{friend.name}}</span>
            <span class="weixin-source">来源：手机通讯录</span>
            <!-- 其他信息，如添加按钮或备注等，可以放在这里 -->
          </div>
        </li>
      </div>
<!--      <li class="weixin-friend-item" @click="titleNmae">
        <img src="avatar1.jpg" alt="好友头像" class="weixin-avatar">
        <div class="weixin-friend-info">
          <span class="weixin-nickname">好友昵称</span>
          <span class="weixin-source">来源：手机通讯录</span>
          &lt;!&ndash; 其他信息，如添加按钮或备注等，可以放在这里 &ndash;&gt;
        </div>
      </li>-->

      <!-- 添加更多好友项 -->
    </ul>
  </section>
  <!-- 可以添加更多好友分组或聊天列表等 -->
</template>

<script>
import {useAuthStore} from "@/stores";
import SvgCreate from "@/views/SvgCreate";

export default {
  name: "LeftSidebar",
  components: {
    SvgCreate
  },
  data() {
    return {
      friendList: [],
    }

  },
  created() {
    this.getFriendList();
  },
  methods:{
    titleNmae(title) {
      // // 使用原生 JavaScript 方法获取子元素（不推荐在 Vue 中这样做，除非有必要）
      // const parentElement = event.currentTarget; // 或者使用 this.$el 来获取组件的根元素
      // const childElement = parentElement.querySelector('.weixin-nickname');
      // console.log(childElement.value); // 输出子元素的 DOM 节点
      //
      // const titleNmae = 'Hello from child';
      //console.log(title);
      this.$emit('updateTitleName', title);
    },

    async getFriendList(){
      const store = useAuthStore();
      try {
        const friendList = await store.getFriendList({"name": store.userInfo.name});
        //console.log(friendList);
        this.friendList = friendList;
      } catch (error){
        console.error(error);
        this.showModalMessage = "密码修改失败";
      }
    },



  }
}
</script>

<style scoped>


/* 好友分组标题样式 */
.weixin-friend-section h2 {
  font-size: 16px;
  margin-top: 0;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

/* 好友项样式 */
.weixin-friend-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.weixin-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.weixin-friend-info {
  /*flex-grow: 1;*/
  display: flex;/* 这里使用flex是为了方便后续可能添加更多子元素时的布局调整 */
  flex-direction: column; /* 将子元素垂直排列 */
  justify-content: space-between; /* 如果需要，可以在子元素之间添加额外的空间 */
}

.weixin-nickname {
  font-size: 14px;
  font-weight: bold;
}

.weixin-source {
  font-size: 12px;
  color: #999;
}
.weixin-nickname,
.weixin-source {
  text-align: left; /* 实际上，对于块级文本元素，这通常是默认行为，所以可能不是必需的 */
}

/* 其他可能的样式，如悬停效果、活跃状态等 */
.weixin-friend-item:hover {
  background-color: #f9f9f9;
}



</style>
