<template>
  <div id="app">
    <nav>
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
      <!-- 注意：这里不会直接链接到 UserProfile，因为它是一个子路由 -->
    </nav>
    <router-view></router-view> <!-- 渲染当前路由的组件 -->
    <!-- 如果你希望渲染子路由的内容在特定位置，可以在 Home.vue 中再添加一个 <router-view> -->
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>
