<template>

  <div class="container" :style="{ height: screenHeight + 'px' }">
    <div class="content">
      <div v-for="(message, index) in messages" :key="index" >

      <div v-if="message.to != localto" class="item item-left"><div class="avatar"><SvgCreate :name="message.from" :bColor="'#c9ba84'"></SvgCreate></div><div class="bubble bubble-left" v-html="message.content" style="text-align: left;"></div></div>
      <div v-if="message.to == localto" class="item item-right"><div class="bubble bubble-right" v-html="message.content" style="text-align: left;"></div><div class="avatar"><SvgCreate :name="message.from" :bColor="'#9ad9c4'"></SvgCreate></div></div>
<!--      <div class="item item-center"><span>昨天 12:35</span></div>-->
<!--      <div class="item item-center"><span>你已添加了凡繁烦，现在可以开始聊天了。</span></div>-->
<!--      <div class="item item-left"><div class="avatar"><img src="https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=25668084,2889217189&fm=26&gp=0.jpg" /></div><div class="bubble bubble-left">您好,我在武汉，你可以直接送过来吗，我有时间的话，可以自己过去拿<br/>！！！<br/>123</div></div>-->

<!--      <div class="item item-right"><div class="bubble bubble-right">hello<br/>你好呀</div><div class="avatar"><img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3313909130,2406410525&fm=15&gp=0.jpg" /></div></div>-->
<!--      <div class="item item-center"><span>昨天 13:15</span></div>-->
<!--      <div class="item item-right"><div class="bubble bubble-right">刚刚不在，不好意思</div><div class="avatar"><img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3313909130,2406410525&fm=15&gp=0.jpg" /></div></div>-->
<!--      <div class="item item-left"><div class="avatar"><img src="https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=25668084,2889217189&fm=26&gp=0.jpg" /></div><div class="bubble bubble-left">没事<br/>你继续！</div></div>-->
<!--      <div class="item item-left"><div class="avatar"><img src="https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=25668084,2889217189&fm=26&gp=0.jpg" /></div><div class="bubble bubble-left">看到了<br/>在下面</div></div>-->
<!--      <div class="item item-left"><div class="avatar"><img src="https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=25668084,2889217189&fm=26&gp=0.jpg" /></div><div class="bubble bubble-left">你发一个<br/>刚刚网卡了</div></div>-->
<!--      <div class="item item-right"><div class="bubble bubble-right">可以<br/>一会儿再发给你</div><div class="avatar"><img src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3313909130,2406410525&fm=15&gp=0.jpg" /></div></div>-->
      </div>
    </div>

<!--    <div v-if="messages.length === 0"  class="content">-->
<!--      没有消息-->
<!--    </div>-->
    <div class="input-area">
      <textarea v-model="newMessage" name="text" id="textarea"></textarea>
      <div class="button-area">
        <button id="send-btn" @click="sendMessage">发 送</button>
      </div>
    </div>
  </div>

</template>

<script>
import {useAuthStore, useMessageStore} from "@/stores";
import CryptoJS from "crypto-js";
import DOMPurify from 'dompurify';

import SvgCreate from './SvgCreate';

const SECRET_KEY = 'your-secret-key'; // 用于生成签名的密钥，请确保其安全性

export default {
  name: "CenterMain",
  components: {
    SvgCreate
  },
  data() {
    return {
      screenHeight: window.innerHeight-60,
      dynamicClass: 'default-class',
      localto: this.to, // 初始化本地数据为 props 的值
      socket: null,
      messages: [],
      newMessage: '',
      privateKeyStr:'',
      key:'',
      wsBaseURL: process.env.VUE_APP_API_WS_BASE_URL, // 对于Vue CLI项目
      // 使用对象字面量来存储聊天信息
      chatData: {
        //"xie": [], // "xie" 的消息数组
        //"xie1": [] // "xie1" 的消息数组
        // 可以添加更多用户
      },
    };
  },
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedInput() {
      // 替换换行符为 <br> 标签
      // 注意：这里假设 userInput 是安全的。如果它可能包含来自外部的数据，你需要先进行消毒处理。
      return this.newMessage.replace(/\n/g, '<br>');
    }
  },
  methods: {
    formattedContent(content) {
      return JSON.stringify(content).replace(/\n/g, '<br>');
    },

    connectWebSocket() {
      const store = useAuthStore();
      //console.log("Connecting to"+process.env.VUE_APP_API_WS_BASE_URL );
      const params = "name="+store.userInfo.name+"&token="+store.userToken;
      //console.log(params);
      this.socket = new WebSocket(this.wsBaseURL+'sendMeg?'+params);
      //console.log(store.generateRandomKey());
      this.key=store.generateRandomKey();
      this.socket.onopen = () => {
        console.log('WebSocket connected');
        // 发送连接请求报文（这里可以根据需要发送更详细的连接信息）
        this.socket.send(JSON.stringify({ type: 'connect', from: store.userInfo.name ,to: this.localto, content: store.encryptMessage(this.key)}));
      };

      this.socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        //console.log('Message'+message);
        //console.log(message);
        if (message.type === 'connect') {
          this.privateKeyStr=store.decryptAESMessage(message.content,this.key);
          //console.log('this.privateKeyStr:'+this.privateKeyStr)

        }else if(message.type === 'message'){
          message.content = store.decryptMessage(message.content,this.privateKeyStr)
          //this.messages.push(message);
          // 添加消息的方法
          this.addMessage(message.from, message)
        }

      };

      this.socket.onclose = () => {
        console.log('WebSocket disconnected');
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
    sendMessage() {
      const store = useAuthStore();
      // const SECRET_KEY = 'your-secret-key'; // 请自行替换为您的应用的 Secret Key
      const timestamp=Date.now();
      this.newMessage =DOMPurify.sanitize(this.newMessage); // 清理用户输入的内容
      // 然后将消毒后的内容绑定到模板中
      // <div v-html="cleanHTML"></div>
      //格式化换行符号
      this.newMessage = this.formattedInput;
      const newENmessage = store.encryptMessage(this.newMessage);
      const dataString = 'message'+timestamp+store.userInfo.name+this.localto+newENmessage
      const generatedSignature = CryptoJS.HmacSHA256(dataString, SECRET_KEY).toString(CryptoJS.enc.Hex);
      const message={
        type: 'message',
        timestamp: timestamp,
        from: store.userInfo.name,
        to: this.localto, // 这里可以根据需要动态设置接收方
        //content: this.newMessage,
        content: newENmessage,
        signature: generatedSignature, // 加入签名
        extra: { readStatus: 'unread', priority: 'normal' }
      };
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify(message));
        message.content = this.newMessage;
        this.newMessage = ''; // 清空输入框
        //this.messages.push(message);
        // 添加消息的方法
        this.addMessage(this.to, message)
      } else {
        console.error('WebSocket is not open or socket is not initialized');
      }
    },
    // 添加消息的方法
    addMessage(user, message) {
      if (!this.chatData[user]) {
        // 如果用户不存在，则初始化一个空数组
        // this.$set(this.chatData, user, []);
        this.chatData[user] = []; // 直接添加新用户，Vue 3 会处理响应性
      }
      this.chatData[user].push(message); // 向用户的消息数组中添加消息
    },
    // 获取用户消息的方法
    getMessages(user) {
      return this.chatData[user] || []; // 如果用户不存在，则返回空数组
    },
    updateScreenHeight() {
      this.screenHeight = window.innerHeight-60;
      console.log("screenHeight:"+this.screenHeight );
      if (this.screenHeight < 600) {
        this.dynamicClass = 'small-screen-class';
      } else if (this.screenHeight < 900) {
        this.dynamicClass = 'medium-screen-class';
      } else {
        this.dynamicClass = 'large-screen-class';
      }
    },

  },
  watch: {
    // 监听 passwords 数组的变化，当最后一个和倒数第二个密码变化时进行检查
    localto: {
      handler() {
       // if (this.socket) {
       //   this.socket.close();
       // }
       // this.connectWebSocket();
       // console.log('watch localto ');
      },
      deep: false, // 需要深度监听，因为 passwords 是一个数组，包含对象
      immediate: true // 立即执行一次监听器
    },
    to:{
      handler(newVal, oldVal) {
        console.log(`Prop myProp changed from ${oldVal} to ${newVal}`);
        this.localto=this.to; // 初始化本地数据为 props 的值
        // 在这里执行你想要的逻辑
        //handler() {
          if (this.socket) {
            this.socket.close();
          }
          this.connectWebSocket();
        // 获取用户消息的方法
        //if(this.messages.length == 0) {
        this.messages = this.getMessages(this.to);
        //}

         // console.log('watch localto11 ');
        //};
      },
      immediate: true // 立即执行一次监听器
    },
    chatData: {
      handler() {
        const messageStore = useMessageStore();
        messageStore.setMessages(this.chatData);
        console.log('watch chatData ');
        console.log(this.chatData);
        // 获取用户消息的方法
        this.messages = this.getMessages(this.to);
      },
      deep: true, // 需要深度监听，因为 passwords 是一个数组，包含对象
      immediate: false // 立即执行一次监听器
    },
  },
  created() {
    const messageStore = useMessageStore();
    this.chatData = messageStore.getMessages();
    console.log('created chatData ');
    console.log(this.chatData);
  },
  // created() {
  //
  //   this.connectWebSocket();
  // },
  // mounted() {
  //   this.connectWebSocket();
  // },
  mounted() {
    this.updateScreenHeight();
    window.addEventListener('resize', this.updateScreenHeight);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenHeight);
    if (this.socket) {
      this.socket.close();
    }
  }


}
</script>

<style scoped>



.container{
  /*height: 760px;*/
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #e0e0e0;
  background-color: #f5f5f5;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.content{
  /*width: calc(100% - 40px);*/
  padding: 20px;
  overflow-y: scroll;
  flex: 1;
}
.content:hover::-webkit-scrollbar-thumb{
  background:rgba(0,0,0,0.1);
}
.bubble{
  max-width: 400px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  color: #000;
  word-wrap:break-word;
  word-break:normal;
}
.item-left .bubble{
  margin-left: 15px;
  background-color: #fff;
  /*left: 150px;*/
}
.item-left .bubble:before{
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid #fff;
  border-bottom: 10px solid transparent;
  left: -20px;
}
.item-right .bubble{
  margin-right: 15px;
  background-color: #9eea6a;
  /*right: 150px;*/
}
.item-right .bubble:before{
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid #9eea6a;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -20px;
}

.item{
  margin-top: 15px;
  display: flex;
  width: 100%;
}
.item.item-right{
  justify-content: flex-end;
}
.item.item-center{
  justify-content: center;
}
.item.item-center span{
  font-size: 12px;
  padding: 2px 4px;
  color: #fff;
  background-color: #dadada;
  border-radius: 3px;
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  -khtml-user-select:none; /*早期浏览器*/
  user-select:none;
}


.avatar img{
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.input-area{
  border-top:0.5px solid #e0e0e0;
  height: 150px;
  display: flex;
  flex-flow: column;
  background-color: #fff;
}
textarea{
  flex: 1;
  padding: 5px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  outline:none;
  resize:none;
}
.button-area{
  display: flex;
  height: 40px;
  margin-right: 10px;
  line-height: 40px;
  padding: 5px;
  justify-content: flex-end;
}
.button-area button{
  width: 80px;
  border: none;
  outline: none;
  border-radius: 4px;
  float: right;
  cursor: pointer;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width:10px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
  border-radius:8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius:10px;
  background:rgba(0,0,0,0);
  -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
</style>
