<template>
  <div className="avatar-container" :style="{backgroundColor:bColor}">
<!--    <div className="avatar-text">{{initials}}</div>-->
    <div className="avatar-text">{{name}}</div>
  </div>
</template>

<script>
  export default {
    name: "SvgCreate",
    data() {
      return {
        bColor1: '#ff0000' // 红色
      };
    },
    props: {
      name: {
        type: String,
        required: true
        },
      bColor: {
        type: String,
        required: true
      },
    },
  computed: {
  initials() {
  const names = this.name.split(' ');
  let initials = '';
  if (names.length > 1) {
  initials = names[0].charAt(0) + names[names.length - 1].charAt(0);
} else {
  initials = names[0].charAt(0).toUpperCase() + names[0].charAt(1).toUpperCase();
  // 如果名字只有一个字，可以只取第一个字母大写，或者加上其他字符使其看起来更像头像
  // 例如：initials = names[0].charAt(0).toUpperCase() + 'O'; // 假设用'O'作为补充字符
}
  return initials;
}
}
};
</script>

<style scoped>
.avatar-container {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  /*background-color: #f0f0f0; !* 你可以根据需要更改背景颜色  #9ad9c4 #c9ba84*!*/
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.avatar-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
