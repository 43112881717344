<template>
  <body>
  <!-- login/register container -->
  <div :class="['container', { active: isActive }]">
    <!-- register -->
    <div class="form-container sign-up-container">
      <div class="form">
        <h2>注册</h2>
        <input v-model="name" type="text" name="username" id="username" placeholder="Username...">
        <input v-model="spare1" type="email" name="emal" id="useremail" placeholder="Email...">
        <input v-model="hsmac" type="password" name="password" id="userpassword" placeholder="Password...">
        <button @click="handleRegister"  class="signUp">注册</button>
      </div>
    </div>
    <!-- login -->
    <div class="form-container sign-in-container">
      <div class="form">
        <h2>登录</h2>
        <input v-model="name" type="text" name="username" id="username " placeholder="Username...">
        <input v-model="hsmac" type="password" name="password" id="password" placeholder="Password...">
        <a href="#" class="forget-password">忘记密码</a>
        <button @click="handleLogin" class="signIn">登录</button>
      </div>
    </div>
    <!-- overlay container -->
    <div class="overlay_container">
      <div class="overlay">
        <!-- overlay left -->
        <div class="overlay_panel overlay_left_container">
          <h2>欢迎回来!</h2>
          <p>若要与我们保持联系，请使用您的个人信息登录</p>
          <button id="sign-in" @click="signIn">登录</button>
        </div>
        <!-- overlay right -->
        <div class="overlay_panel overlay_right_container">
          <h2>你好朋友!</h2>
          <p>输入您的个人资料，并与我们一起开始旅程</p>
          <button id="sign-up" @click="signUp">注册</button>
        </div>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import { useAuthStore } from '@/stores/authStore';
import CryptoJS from 'crypto-js';
// import { reactive } from 'vue';
// import { Form, Input, Button, Layout, Menu, MenuItem } from 'ant-design-vue';

export default {
  name: 'UserRegister',
  data() {
    // 在 data 中，我们只存储本地变量，这些变量不会直接与 store 同步
    return {
      isActive: false, // 控制 'active' 类是否激活的响应式数据
      name: '',
      hsmac: '',
      spare1:'',
      md5_secret_key: process.env.MD5_SECRET_KEY
      // 我们不直接存储 errorMessage，因为这将与 store 中的状态冲突
      // 而是直接从 store 中获取它
    };
  },
  computed: {
    // 创建一个计算属性来访问 store 中的状态
    errorMessage() {
      const store = useAuthStore();
      return store.errorMessage;
    },
    // 注意：我们不在这里直接映射整个 store 的状态，因为这会导致每次访问时都创建一个新的 store 实例
    // 相反，我们只为需要同步的字段创建单独的计算属性（如果需要的话）
    // 但在这个例子中，我们不需要这样做，因为我们会直接在 methods 中处理登录逻辑
  },
  watch: {
    // 使用 watch 来观察本地变量的变化，并同步到 store 中（如果需要的话）
    // 但在这个例子中，我们直接在 handleLogin 方法中处理这些变量
    // 因此，下面的 watch 监听器是多余的，只是为了说明如何同步数据
    // localUsername(newVal) {
    //   const store = useAuthStore();
    //   store.username = newVal; // 实际上，我们不需要这样做，因为我们在登录时直接传递了这些值
    // },
    // localPassword(newVal) {
    //   const store = useAuthStore();
    //   store.password = newVal; // 同样，我们不需要这样做
    // },
  },
  methods: {
    signUp() {
      this.isActive = true; // 当点击注册按钮时，激活 'active' 类
    },
    signIn() {
      this.isActive = false; // 当点击登录按钮时，取消激活 'active' 类
    },
    async handleLogin() {
      // const router = useRouter();
      const store = useAuthStore();
      // 在这里，我们直接从本地变量中获取用户名和密码
      // 并调用 store 的 login 方法进行登录
      const combinedString=this.hsmac+this.md5_secret_key;
      // 使用 CryptoJS 计算 MD5 哈希值
      var md5Hash = CryptoJS.MD5(combinedString).toString(CryptoJS.enc.Hex);

      try {
        await store.login({"name": this.name, "hsmac": md5Hash});
        // 登录后，store 的状态会自动更新，因为 errorMessage 是一个计算属性
        // 所以它会自动反映 store 中 errorMessage 的变化
        // 如果登录方法没有抛出错误，说明登录成功
        // 进行页面跳转
        // await router.push({name: 'Home'});
        await this.$router.push({name: 'Home'});
      } catch (error) {
        // 如果登录方法抛出了错误，说明登录失败
        // 处理登录错误，例如显示错误消息
        console.error('Login failed:', error);
        // 可以在这里添加额外的错误处理逻辑，比如显示一个错误弹窗
      }

    },

    async handleRegister() {
      // const router = useRouter();
      const store = useAuthStore();
      // 在这里，我们直接从本地变量中获取用户名和密码
      // 并调用 store 的 login 方法进行登录
      const combinedString=this.hsmac+this.md5_secret_key;
      // 使用 CryptoJS 计算 MD5 哈希值
      var md5Hash = CryptoJS.MD5(combinedString).toString(CryptoJS.enc.Hex);

      try {
        await store.register({"name": this.name, "hsmac": md5Hash,"spare1":this.spare1});
        await this.$router.push({name: 'Login'});
        this.isActive = false; // 当点击登录按钮时，取消激活 'active' 类
      } catch (error) {
        // 如果登录方法抛出了错误，说明登录失败
        // 处理登录错误，例如显示错误消息
        console.error('Register failed:', error);
        // 可以在这里添加额外的错误处理逻辑，比如显示一个错误弹窗
      }

    },


  },


  created() {
    // console.log('Created');
    // console.log(this.$route);
    this.isActive=(this.$route.name=="Register")
    // 从查询字符串中获取用户名并赋值给 data 属性
    // this.userName = this.$route.query.name || 'Guest'; // 如果没有提供 name 参数，则默认为 'Guest'
  },
  // 通常情况下，我们不需要在 mounted 钩子中做什么，因为 Vue 会自动处理 store 的依赖
  // 但如果你需要在组件加载时执行一些与 store 相关的初始化操作
  // 你可以在这里做（比如检查用户是否已经登录）
  mounted() {
    // const store = useAuthStore();
    // // 例如，检查用户是否已经登录，并据此执行一些操作
    // if (!store.isLoggedIn) {
    //   // ...
    // }
    // 注意：在这个例子中，我们不需要这样做，因为登录逻辑是在用户提交表单时处理的
  },
  // 同样，我们也不需要 beforeDestroy 或 destroyed 钩子来处理 store 的清理工作
  // 因为 Pinia 会自动管理 store 的生命周期
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f6f5f7;
}

h2 {
  margin-bottom: 10px;
  font-size: 32px;
  text-transform: capitalize;
}

.container {
  position: relative;
  width: 768px;
  height: 480px;
  background-color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.form-container {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: white;
  transition: all 0.6s ease-in-out;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 50px;
}

input {
  width: 100%;
  margin: 8px 0;
  padding: 12px;
  background-color: #eee;
  border: none;
}

.forget-password {
  display: inline-block;
  height: 20px;
  text-decoration: none;
  color: #bbb;
  text-transform: capitalize;
  font-size: 12px;
}

.forget-password:hover {
  color: lightslategray;
  border-bottom: 2px solid #ff4b2b;
}

button {
  background: #ff4b2b;
  padding: 10px 50px;
  border: 1px solid transparent;
  border-radius: 20px;
  text-transform: uppercase;
  color: white;
  margin-top: 10px;
  outline: none;
  transition: transform 80;
}

button:active {
  transform: scale(0.95);
}

.overlay_container {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 100;
  right: 0;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}

.overlay {
  position: absolute;
  width: 200%;
  height: 100%;
  left: -100%;
  background-color: #ff4b2b;
}

.overlay_panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  color: white;
  padding: 0 40px;
  text-align: center;
}

.overlay_panel button {
  background-color: transparent;
  border: 1px solid white;
}

.overlay_panel p {
  font-size: 12px;
  margin: 10px 0 15px 0;
}

.overlay_right_container {
  right: 0;
}

.container.active .sign-up-container {
  transform: translateX(100%);
  z-index: 5;
}

.container.active .sign-in-container {
  transform: translateX(100%);
}

.container.active .overlay_container {
  transform: translateX(-100%);
}

.container.active .overlay {
  transform: translateX(50%);
}
</style>
